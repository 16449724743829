let CONSTANTS = {

    toggle: {
        width: 53,
        color: '#38c172',
        labels: { checked: 'Yes', unchecked: 'No' }
    },

    months: [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ],

    timezones: {
        'Pacific/Midway'       : "(GMT-11:00)",
        'US/Samoa'             : "(GMT-11:00)",
        'US/Hawaii'            : "(GMT-10:00)",
        'US/Alaska'            : "(GMT-09:00)",
        'US/Pacific'           : "(GMT-08:00)",
        'America/Tijuana'      : "(GMT-08:00)",
        'US/Arizona'           : "(GMT-07:00)",
        'US/Mountain'          : "(GMT-07:00)",
        'America/Chihuahua'    : "(GMT-07:00)",
        'America/Mazatlan'     : "(GMT-07:00)",
        'America/Mexico_City'  : "(GMT-06:00)",
        'America/Monterrey'    : "(GMT-06:00)",
        'Canada/Saskatchewan'  : "(GMT-06:00)",
        'US/Central'           : "(GMT-06:00)",
        'US/Eastern'           : "(GMT-05:00)",
        'US/East-Indiana'      : "(GMT-05:00)",
        'America/Bogota'       : "(GMT-05:00)",
        'America/Lima'         : "(GMT-05:00)",
        'America/Caracas'      : "(GMT-04:30)",
        'Canada/Atlantic'      : "(GMT-04:00)",
        'America/La_Paz'       : "(GMT-04:00)",
        'America/Santiago'     : "(GMT-04:00)",
        'Canada/Newfoundland'  : "(GMT-03:30)",
        'America/Buenos_Aires' : "(GMT-03:00)",
        'Greenland'            : "(GMT-03:00)",
        'Atlantic/Stanley'     : "(GMT-02:00)",
        'Atlantic/Azores'      : "(GMT-01:00)",
        'Atlantic/Cape_Verde'  : "(GMT-01:00)",
        'Africa/Casablanca'    : "(GMT)",
        'Europe/Dublin'        : "(GMT)",
        'Europe/Lisbon'        : "(GMT)",
        'Europe/London'        : "(GMT)",
        'Africa/Monrovia'      : "(GMT)",
        'Europe/Amsterdam'     : "(GMT+01:00)",
        'Europe/Belgrade'      : "(GMT+01:00)",
        'Europe/Berlin'        : "(GMT+01:00)",
        'Europe/Bratislava'    : "(GMT+01:00)",
        'Europe/Brussels'      : "(GMT+01:00)",
        'Europe/Budapest'      : "(GMT+01:00)",
        'Europe/Copenhagen'    : "(GMT+01:00)",
        'Europe/Ljubljana'     : "(GMT+01:00)",
        'Europe/Madrid'        : "(GMT+01:00)",
        'Europe/Paris'         : "(GMT+01:00)",
        'Europe/Prague'        : "(GMT+01:00)",
        'Europe/Rome'          : "(GMT+01:00)",
        'Europe/Sarajevo'      : "(GMT+01:00)",
        'Europe/Skopje'        : "(GMT+01:00)",
        'Europe/Stockholm'     : "(GMT+01:00)",
        'Europe/Vienna'        : "(GMT+01:00)",
        'Europe/Warsaw'        : "(GMT+01:00)",
        'Europe/Zagreb'        : "(GMT+01:00)",
        'Europe/Athens'        : "(GMT+02:00)",
        'Europe/Bucharest'     : "(GMT+02:00)",
        'Africa/Cairo'         : "(GMT+02:00)",
        'Africa/Harare'        : "(GMT+02:00)",
        'Europe/Helsinki'      : "(GMT+02:00)",
        'Europe/Istanbul'      : "(GMT+02:00)",
        'Asia/Jerusalem'       : "(GMT+02:00)",
        'Europe/Kiev'          : "(GMT+02:00)",
        'Europe/Minsk'         : "(GMT+02:00)",
        'Europe/Riga'          : "(GMT+02:00)",
        'Europe/Sofia'         : "(GMT+02:00)",
        'Europe/Tallinn'       : "(GMT+02:00)",
        'Europe/Vilnius'       : "(GMT+02:00)",
        'Asia/Baghdad'         : "(GMT+03:00)",
        'Asia/Kuwait'          : "(GMT+03:00)",
        'Africa/Nairobi'       : "(GMT+03:00)",
        'Asia/Riyadh'          : "(GMT+03:00)",
        'Europe/Moscow'        : "(GMT+03:00)",
        'Asia/Tehran'          : "(GMT+03:30)",
        'Asia/Baku'            : "(GMT+04:00)",
        'Europe/Volgograd'     : "(GMT+04:00)",
        'Asia/Muscat'          : "(GMT+04:00)",
        'Asia/Tbilisi'         : "(GMT+04:00)",
        'Asia/Yerevan'         : "(GMT+04:00)",
        'Asia/Kabul'           : "(GMT+04:30)",
        'Asia/Karachi'         : "(GMT+05:00)",
        'Asia/Tashkent'        : "(GMT+05:00)",
        'Asia/Kolkata'         : "(GMT+05:30)",
        'Asia/Kathmandu'       : "(GMT+05:45)",
        'Asia/Yekaterinburg'   : "(GMT+06:00)",
        'Asia/Almaty'          : "(GMT+06:00)",
        'Asia/Dhaka'           : "(GMT+06:00)",
        'Asia/Novosibirsk'     : "(GMT+07:00)",
        'Asia/Bangkok'         : "(GMT+07:00)",
        'Asia/Jakarta'         : "(GMT+07:00)",
        'Asia/Krasnoyarsk'     : "(GMT+08:00)",
        'Asia/Chongqing'       : "(GMT+08:00)",
        'Asia/Hong_Kong'       : "(GMT+08:00)",
        'Asia/Kuala_Lumpur'    : "(GMT+08:00)",
        'Australia/Perth'      : "(GMT+08:00)",
        'Asia/Singapore'       : "(GMT+08:00)",
        'Asia/Taipei'          : "(GMT+08:00)",
        'Asia/Ulaanbaatar'     : "(GMT+08:00)",
        'Asia/Urumqi'          : "(GMT+08:00)",
        'Asia/Irkutsk'         : "(GMT+09:00)",
        'Asia/Seoul'           : "(GMT+09:00)",
        'Asia/Tokyo'           : "(GMT+09:00)",
        'Australia/Adelaide'   : "(GMT+09:30)",
        'Australia/Darwin'     : "(GMT+09:30)",
        'Asia/Yakutsk'         : "(GMT+10:00)",
        'Australia/Brisbane'   : "(GMT+10:00)",
        'Australia/Canberra'   : "(GMT+10:00)",
        'Pacific/Guam'         : "(GMT+10:00)",
        'Australia/Hobart'     : "(GMT+10:00)",
        'Australia/Melbourne'  : "(GMT+10:00)",
        'Pacific/Port_Moresby' : "(GMT+10:00)",
        'Australia/Sydney'     : "(GMT+10:00)",
        'Asia/Vladivostok'     : "(GMT+11:00)",
        'Asia/Magadan'         : "(GMT+12:00)",
        'Pacific/Auckland'     : "(GMT+12:00)",
        'Pacific/Fiji'         : "(GMT+12:00)",
    },

    orders: {
        logo_positions: [
            'Above Right',
            'Above Center',
            'Above Left',
            'Grid 1',
            'Grid 2',
            'Grid 3',
            'Grid 4'
        ],

        view_types: [
            'Expanded',
            'Full',
            'Column',
            'Commercial',
            'Compact',
            'Consolidated',
            'Total Price Only'
        ],

        font_styles: [
            'Arial',
            'Lato',
            'Open Sans',
            'Roboto',
            'Source Sans Pro',
            'PT Sans',
            'Crimson Text',
        ]
    },

    measure_and_install: {
        view_types: [
            'Expanded',
            'Compact',
            'Column',
        ],
    },

    quicklinks: [
        {
            title: '<i class="fa fa-chart-line"></i> &nbsp; Dashboard',
            route: 'site.dashboard.index',
            link: '#nav-home',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-chart-pie"></i> &nbsp; Performance Dashboard',
            route: 'site.performance-dashboard.index',
            link: '#nav-performance-dashboard',
            request: 'GET'
        },
       /* {
            title: '<i class="fa fa-chart-pie"></i> &nbsp; New System Ideas in Feedback',
            route: 'site.system-feedback-dashboard.index',
            link: '#nav-system-feedback-dashboard',
            request: 'GET'
        } ,*/
        // {
        //     title: '<i class="fa fa-calendar"></i> &nbsp; Appointments',
        //     route: 'site.appointments.index',
        //     link: 'scheduling#nav-scheduler',
        //     request: 'GET'
        // },
        {
            title: '<i class="fa fa-calendar"></i> &nbsp; Scheduler',
            route: 'site.scheduler.index',
            link: 'scheduling#nav-scheduler',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-wrench"></i> &nbsp; Measure and Installation',
            route: 'site.measure-and-installation.index',
            link: 'measure-and-install',
            request: 'GET'
        },
        /*{
            title: '<i class="fa fa-quote-right"></i> &nbsp; Quick Quotes',
            route: 'site.quotes.quick',
            link: 'quotes-and-orders#nav-quotes'
        },*/
        {
            title: '<i class="fa fa-quote-right"></i> &nbsp; Quotes',
            route: 'site.quotes.index',
            link: 'quotes-and-orders#nav-quotes',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-sitemap"></i> &nbsp; Workflow Manager',
            route: 'site.workflow-manager.index',
            link: 'workflow-manager',
            request: 'GET'
        },
        /*{
            title: '<i class="fa fa-grip-lines"></i> &nbsp; Opportunity Pipeline',
            route: 'site.opportunity-pipeline.index',
            link: '#',
            request: 'GET'
        },*/
        {
            title: '<i class="fa fa-chart-area"></i> &nbsp; Reports',
            route: 'site.reports.index',
            link: '#nav-reports',
            request: 'GET'
        },
        /*{
            title: '<i class="fa fa-book"></i> &nbsp; Sales Ledger',
            route: 'site.sales-ledger.index',
            link: '#',
            request: 'GET'
        },*/
        {
            title: '<i class="fa fa-dollar-sign"></i> &nbsp; Product Settings',
            route: 'site.product-settings.index',
            link: 'settings#nav-product-settings',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-minus-circle"></i> &nbsp; Product Measurements',
            route: 'site.product-deductions.index',
            link: 'settings#nav-product-measurements',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-cogs"></i> &nbsp; Item Settings',
            route: 'site.item-settings.index',
            link: 'settings#nav-item-settings',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-check-circle"></i> &nbsp; Item Check',
            route: 'site.item-check.index',
            link: 'settings#nav-item-check',
            request: 'GET'
        },
        /*{
            title: '<i class="fa fa-building"></i> &nbsp; Companies',
            route: 'site.companies.index',
            link: 'settings#nav-company-settings',
            request: 'GET'
        },*/
        {
            title: '<i class="fa fa-address-book"></i> &nbsp; Contacts',
            route: 'site.contacts.index',
            link: 'quotes-and-orders#nav-contacts',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-users"></i> &nbsp; Employee Manager',
            route: 'site.employee-manager.index',
            link: 'settings#nav-employee-and-roles',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-file"></i> &nbsp; Documents',
            route: 'site.documents.index',
            link: 'documents',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-lock"></i> &nbsp; Roles',
            route: 'site.roles.index',
            link: 'settings#nav-employee-and-roles',
            request: 'GET'
        },
        {
            title: '<i class="fa fa-pencil-alt"></i> &nbsp; Quick Quote',
            route: 'site.quotes.quickQuote',
            link: '/api/quotes/quickQuote',
            request: 'POST'
        },
    ],

    event: {
        types: [
            { value: 'measure', label: 'Measure' },
            { value: 'install', label: 'Install' },
            { value: 'manual-measure', label: 'Manual Measure' },
            { value: 'manual-install', label: 'Manual Install' },
            { value: 'sales', label: 'Sales' },
            { value: 'service', label: 'Service' },
            { value: 'misc', label: 'Misc' },
            { value: 'job problem', label: 'Job Problem' },
        ],
        statuses: [
            { value: 'pending', label: 'Pending' },
            { value: 'confirmed', label: 'Confirmed' },
            { value: 'cancelled', label: 'Cancelled' },
        ]
    },

    discountTypes: [
        { value: 'discount allowed', label: 'Discount Allowed' },
        { value: 'no discount on this product', label: 'No discount on this product' },
        { value: 'no discount on options', label: 'No discount on options' }
    ],

    adjustments: ['% Mark-Up', 'Split Mark-Up', '% Discount', '% Margin', 'Split Margin'],

    scheduler: {
        arrangement: [
            'title',
            'type',
            'order',
            'full_name',
            'schedule',
            'mobile_number',
            'home_full_address',
            'appointment_status',
            'notes'
        ],
    },

    itemCategories: [{'name':'Fabric', 'value':'1'}, {'name':'Trim', 'value':'2'}, {'name':'Accessory', 'value':'3'}],

    // CHART Filters
    reportTypes: [
        {
            'name': 'Sales by Employee - Summary (Profit & Conversion)',
            'value':'sales_report_2',
            'permission' : 'sales_performance_totals_2',
            'desc' : 'Total sales by employee for the selected date range including average sales, discounts given and gross profit by value and margin. Also shows the quantity of leads, appointments, quotes and sales (orders). Sales are only for any quotes converted to a sale during the selected date range.'
        },
        {
            'name': 'Sales by Employee – Order Details (Profit)',
            'value': 'sales_list_by_person_2',
            'permission' : 'sales_listed_per_person_2',
            'desc': "Measures the total sales by employee for a selected date range. It shows each order's sales details, including value, discount given and gross profit by value and margin. Sales are measured as any quotes converted to orders during a selected date range."
        },
        {
            'name': 'Sales by Referral Channel - Summary (Profit & Conversion) ',
            'value': 'referred_by_sales_report_2',
            'permission' : 'referred_by_sales_report',
            'desc': "Measures the total sales by referral channel / lead source for a selected date range including average sales, discounts given and gross profit by value and margin. Also shows the conversion rates of referrals and leads to sales (orders). Sales are measured as any quotes converted to orders during the selected date range."
        },
        {
            'name': 'Sales by Referral Channel – Order Details (Profit)',
            'value': 'referred_by_sub_category_sales_report_2',
            'permission' : 'referred_by_sub_category_sales_report',
            'desc': "Measures the total sales by referral channel / lead source for a selected date range with individual order details by sub category, sales value, discount given and gross profit by value and margin. Sales are measured as any quotes converted to orders during the selected date range."
        },
        {
            'name': 'Sales By Product Category',
            'value': 'sales_by_category',
            'permission' : 'sales_by_product_category',
            'desc': "Measures the total sales by product category for a selected date range including average sales and gross profit by value and margin. Any discounts are applied to sales values in proportion to the value of each category in an order. Sales are measured as any quotes converted to orders during the selected date range. "
        },
        {
            'name': 'Sales by Employee vs Monthly Target',
            'value': 'sales_target',
            'permission' : 'actual_sales_vs_sales_target_per_person',
            'desc': "Measures the total sales by employee compared to their sales target for a selected month. Sales targets can be set for employees in Settings > Employees & Roles. Sales are measured as any quotes converted to orders during the selected date range."
        },
        {
            'name': 'Sales Commission Transactions - Summary',
            'value':'sales_report',
            'permission' : 'sales_performance_totals',
            'desc': "Measures the total of any sales transactions entered by an employee for a selected date range by order and revision details, and it provides more information on the sale regarding the transaction history and lead source. It includes sales for any quotes converted to a sale during the selected date range plus any adjustments to orders from previous time periods. Used as the basis for sales values when calculating sales commission. <br/> Sales are measured as any quotes converted to orders during the selected date range. "
        },
        { 'name': 'Sales Commission Transactions - Detail',
            'value': 'sales_list_by_person',
            'permission' : 'sales_listed_per_person',
            'desc': "Measures the total of any sales transactions entered by an employee for a selected date range by order and revision details, and it provides more information on the sale regarding the transaction history and lead source. It includes sales for any quotes converted to a sale during the selected date range plus any adjustments to orders from previous time periods. Used as the basis for sales values when calculating sales commission. Sales are measured as any quotes converted to orders during the selected date range."
        },
        {
            'name': 'Quotes: Open Quotes by Employee ',
            'value': 'quote_list',
            'permission' : 'list_of_all_quotes_not_converted_to_an_order',
            'desc': "Lists all open quotes on the <strong class='text-black'>Quotes</strong> module grouped by employee that have not been converted to an order (sale) for a selected date range."
        },
        {
            'name': 'Faults',
            'value': 'faults',
            'permission' : 'faults_report',
            'desc': "Lists individual orders recorded against each <strong class='text-black'>Fault Type</strong> for a selected date range. To record a <strong class='text-black'>Fault Type</strong>, go to the <strong class='text-black'>Quotes</strong> module and either: <br/> - Click <strong class='text-black'>Edit Quote</strong> (or <strong class='text-black'>Edit Order</strong>) > <strong class='text-black'>Customer Info</strong> > <strong class='text-black'>Edit Customer Information</strong>, or <br/> - Click <strong class='text-black'>Create New Quote</strong> and select <strong class='text-black'>Fault</strong>, <strong class='text-black'>Rework</strong> or <strong class='text-black'>Warranty Type Jobs</strong> then choose the <strong class='text-black'>Fault Type</strong>."
        },
        {
            'name': 'Conversion Summary (Lead/Appointment/Quote/Order)',
            'value': 'appointment_quotes',
            'permission' : 'qnty_of_appntmnts_vs_qnty_of_qts_per_person',
            'desc': "Measures the total volume of leads, appointments, quotes and orders (sales) per employee for a selected date range. Used to compare conversion rates to sales between employees. Sales are measured as any quotes converted to orders during the selected date range. "
        },
        {
            'name': 'Workflow Manager: Work on Hand by Assignment',
            'value': 'work_on_hand_report',
            'permission' : 'work_on_hand_report',
            'desc': "Measures the total volume and value of work assigned to each employee by the order stages: Check Measure, In Production and Ready to Fit. To create this report, the <strong class='text-black'>Add Assign Tag</strong> for each order in the Workflow Manager must be used to assign the employee."
        },
        {
            'name': 'Workflow Manager: Work on Hand Report by Status ',
            'value': 'work_on_hand_by_status_report',
            'permission' : 'work_on_hand_by_status_report',
            'desc': "Measures the total volume and value of work in progress according to the status of each order as per the <strong class='text-black'>Status</strong> column in the Workflow Manager. Note that any order where the <strong class='text-black'>Status</strong> is <strong class='text-black'>Completed</strong> in the Workflow Manager is excluded from this report. Sales are measured as any quotes converted to orders."
        },
        {
            'name': 'Workflow Manager: Active Order Audit',
            'value': 'sales_ledger',
            'permission' : 'non_archived_sales_ledger',
            'desc': "Lists all Active orders on the Workflow Manager per employee showing order details, sales value and amounts paid / owing. Used to audit Quoterite transactions against your accounting / banking transaction listings. Sales are measured as any quotes converted to orders. "
        },
        {
            'name': 'Workflow Manager: Archived Order Audit',
            'value': 'completed_jobs',
            'permission' : 'all_archived_jobs_on_the_workflow_manager',
            'desc': "Lists all Archived orders on the Workflow Manager per employee showing order details, sales value and amounts paid / owing for a selected date range. Used to audit Quoterite transactions against your accounting / banking transaction listings. Sales are measured as any quotes converted to orders during the selected date range. "
        },
        {
            'name': 'Customer Payment Transaction Audit',
            'value': 'receipt_report',
            'permission' : 'client_payment_received',
            'desc': "Lists all customer payments and method recorded in Quoterite for a selected date range. Use this report to audit Quoterite transactions against your accounting / banking transaction listings."
        },
        {
            'name': 'Workflow Manager: Work on Hand by Assignment - Detail',
            'value': 'work_on_hand_order_details',
            'permission' : 'work_on_hand_order_details',
            'desc': "Measures the total volume and value of work by order and revision number assigned to each employee grouped by the order stages: Check Measure, In Production and Ready to Fit. To create this report, the Add Assign Tag for each order in Workflow Manager must be used to assign the employee."
        },
        {
            'name': 'Sales by Supplier Brand',
            'value': 'sales_by_brand',
            'permission' : 'sales_by_brand',
            'desc': 'Measures total sales grouped by brand, including average sales and gross profit by value and margin, for a selected date range. Any discounts are applied to sales values in proportion to the value of each brand in an order. Sales are measured as any quotes converted to orders during the selected date range. <br /> This report uses the full retail price on all sales as discounts cannot be taken into account.'
        },
        {
            'name': 'Supplier Orders by ETA',
            'value': 'supplier_orders_by_eta',
            'permission' : 'supplier_orders_by_eta',
            'desc': "Tracks the Expected Time of Arrival (ETA) for orders grouped by supplier, brand and product category."
        },
        {
            'name': 'Install Booked, Products Not Received',
            'value': 'install_booked_products_not_received',
            'permission' : 'install_booked_products_not_received',
            'desc': "Tracks the orders in the Workflow Manager where products for installations booked in the selected date range are yet to be received from suppliers. This includes orders with the following statuses: <br /> - <strong class='text-black'>Install Status</strong> is marked as: <strong class='text-black'>In Scheduling</strong> or <strong class='text-black'>Booked</strong> <br /> - <strong class='text-black'>Order Status</strong> is <strong class='text-black'>not</strong> marked as: <strong class='text-black'>Fully Received</strong>."
        },
        {
            'name': 'Install Not Completed',
            'value': 'install_not_completed',
            'permission' : 'install_not_completed',
            'desc': 'Tracks the receival of products from suppliers against the install status for orders in the Workflow Manager. You can filter the number of days since products have been received but not yet installed.'
        },
        {
            'name': 'Meeting Activity Report',
            'value': 'meeting_activity',
            'permission' : 'meeting_activity',
            'desc': '--'
        },
        {
            'name': 'Not Ordered Project Report (Product Orders)',
            'value': 'not_ordered_project',
            'permission' : 'not_ordered_project',
            'desc': '--'
        },
        {
            'name': 'Ordered Project Report',
            'value': 'ordered_project',
            'permission' : 'ordered_project',
            'desc': '--'
        },
        {
            'name': 'Sales by Product',
            'value': 'sales_by_product',
            'permission' : 'sales_by_product',
            'desc': '--'
        },
        {
            'name': 'Sales by Referral Source',
            'value': 'sales_by_referral_source',
            'permission' : 'sales_by_referral_source',
            'desc': '--'
        },
        {
            'name': 'Measure Status - Action Required',
            'value': 'check_final_measure',
            'permission' : 'check_final_measure',
            'desc': "Lists all orders in the Workflow Manager that require a measure, i.e. any orders with a measure status of <strong class='text-black'>Required</strong> or with no measure status entered. Excludes orders with a <strong class='text-black'>Measure Status</strong> of <strong class='text-black'>Not Required</strong>, <strong class='text-black'>Completed</strong> or <strong class='text-black'>Fully Ordered</strong>."
        },
        {
            'name': 'Opportunity Quotes',
            'value': 'opportunity_quotes',
            'permission' : 'opportunity_quotes',
            'desc': '--'
        },
        {
            'name': 'Sales Pipeline',
            'value': 'commercial_sales_pipeline',
            'permission' : 'commercial_sales_pipeline',
            'desc': '--'
        },
        {
            'name': 'Sales by Employee - Summary (Profit & Conversion)',
            'value':'commercial_sales_report_2',
            'permission' : 'commercial_sales_performance_totals_2',
            'desc' : "Measures total sales by employee for a selected date range including average sales, discounts given and gross profit by value and margin. Also shows the quantity of leads, appointments, quotes and sales. Sales are measured as any quotes converted to orders during the selected date range. "
        },
        {
            'name': 'Sales by Employee – Order Details (Profit)',
            'value': 'commercial_sales_list_by_person_2',
            'permission' : 'commercial_sales_listed_per_person_2',
            'desc': "Measures the total sales by employee for a selected date range. It shows each order's sales details, including value, discount given and gross profit by value and margin. Sales are measured as any quotes converted to orders during a selected date range."
        },
        {
            'name': 'Sales Commission Transactions - Summary',
            'value':'commercial_sales_report',
            'permission' : 'commercial_sales_performance_totals',
            'desc': "Measures the total of any sales transactions entered by an by employee for the selected date range. It includes sales for any quotes converted to a sale during the selected period plus any adjustments to orders from previous time periods. Use this report as the basis for sales values when calculating sales commission. Sales are measured as any quotes converted to orders during the selected date range. "
        },
        { 'name': 'Sales Commission Transactions - Detail',
            'value': 'commercial_sales_list_by_person',
            'permission' : 'commercial_sales_listed_per_person',
            'desc': "Measures the total of any sales transactions entered by an employee for a selected date range by order and revision details, and it provides more information on the sale regarding the transaction history and lead source. It includes sales for any quotes converted to a sale during the selected date range plus any adjustments to orders from previous time periods. Used as the basis for sales values when calculating sales commission. Sales are measured as any quotes converted to orders during the selected date range."
        },

        {
            'name': 'Sales By Product Category',
            'value': 'commercial_sales_by_category',
            'permission' : 'commercial_sales_by_product_category',
            'desc': "Measures the total sales by product category for a selected date range including average sales and gross profit by value and margin. Any discounts are applied to sales values in proportion to the value of each category in an order. <br/> Sales are measured as any quotes converted to orders during the selected date range."
        },
        {
            'name': 'Faults',
            'value': 'commercial_faults',
            'permission' : 'commercial_faults_report',
            'desc': "Lists individual orders recorded against each <strong class='text-black'>Fault Type</strong> for a selected date range. To record a <strong class='text-black'>Fault Type</strong>, go to the <strong class='text-black'>Quotes</strong> module and either: <br/> - Click <strong class='text-black'>Edit Quote</strong> (or <strong class='text-black'>Edit Order</strong>) > <strong class='text-black'>Customer Info</strong> > <strong class='text-black'>Edit Customer Information</strong>, or <br/> - Click <strong class='text-black'>Create New Quote</strong> and select <strong class='text-black'>Fault</strong>, <strong class='text-black'>Rework</strong> or <strong class='text-black'>Warranty Type Jobs</strong> then choose the <strong class='text-black'>Fault Type</strong>."
        },
        {
            'name': 'Sales by Supplier Brand',
            'value': 'commercial_sales_by_brand',
            'permission' : 'commercial_sales_by_brand',
            'desc': "Measures total sales grouped by brand, including average sales and gross profit by value and margin, for a selected date range. Any discounts are applied to sales values in proportion to the value of each brand in an order. Sales are measured as any quotes converted to orders during the selected date range. "
        },
        {
            'name': 'Supplier Orders by ETA',
            'value': 'commercial_supplier_orders_by_eta',
            'permission' : 'commercial_supplier_orders_by_eta',
            'desc': 'Tracks the Expected Time of Arrival (ETA) for orders grouped by supplier, brand and product category.'
        },
        {
            'name': 'Sales By Business',
            'value': 'commercial_sales_by_business',
            'permission' : 'commercial_sales_by_business',
            'desc': '--'
        },
        {
            'name': 'Sales By Contact',
            'value': 'commercial_sales_by_contact',
            'permission' : 'commercial_sales_by_contact',
            'desc': '--'
        },
        {
            'name': 'Sales by Ref By/Lead Source',
            'value': 'commercial_sales_by_referred_by',
            'permission' : 'commercial_sales_by_referred_by',
            'desc': '--'
        },
        {
            'name': 'Products on Hand',
            'value': 'commercial_products_on_hand',
            'permission' : 'commercial_products_on_hand',
            'desc': '--'
        },
        {
            'name': 'Completed Job with Outstanding Payments',
            'value': 'commercial_completed_job_with_outstanding_payments',
            'permission' : 'commercial_completed_job_with_outstanding_payments',
            'desc': '--'
        },
        {
            'name': 'Appointments',
            'value': 'appointments',
            'permission' : 'appointments',
            'desc': 'Shows all appointments made, the type and status, and the conversions to quotes and orders by employee for a selected date range. Sales are measured as any quotes converted to orders during the selected date range. '
        },
        {
            'name': 'Leads vs Sales',
            'value': 'leads_vs_sales',
            'permission' : 'leads_vs_sales',
            'desc': 'Shows the conversion rate of leads to sales per employee for a selected date range, and the details of the sales orders for each employee, including sale date, value and gross profit. Sales are measured as any leads / quotes converted to orders during the selected date range. '
        },
        {
            'name': 'Product on Hand to be Install',
            'value': 'product_on_hand_to_be_install',
            'permission' : 'product_on_hand_to_be_install',
            'desc': 'Shows the product received per order number and product category, and the amount owing for the order, the status of the install, and if any faults are associated with the order.'
        },
        {
            'name': 'Install Charge',
            'value': 'install_charge',
            'permission' : 'install_charge',
            'desc': 'Shows the install charge of products per order and the assigned fitter for a selected date range.'
        },
        {
            'name': 'Install Booked, Products Not Received',
            'value': 'commercial_install_booked_products_not_received',
            'permission' : 'commercial_install_booked_products_not_received',
            'desc': "Tracks the orders in the Workflow Manager where products for installations booked in the selected date range are yet to be received from suppliers. This includes orders with the following statuses: <br />- <strong class='text-black'>Install Status</strong> is marked as: <strong class='text-black'>In Scheduling</strong> or <strong class='text-black'>Booked</strong> <br />- <strong class='text-black'>Order Status</strong> is not marked as: <strong class='text-black'>Fully Received</strong>."
        },
        {
            'name': 'Workflow Manager: Active Order Audit',
            'value': 'commercial_sales_ledger',
            'permission' : 'commercial_non_archived_sales_ledger',
            'desc': "Lists all Active orders on the Workflow Manager per employee showing order details, sales value and amounts paid / owing. Used to audit Quoterite transactions against your accounting / banking transaction listings. Sales are measured as any quotes converted to orders."
        },
        {
            'name': 'Workflow Manager: Archived Order Audit',
            'value': 'commercial_completed_jobs',
            'permission' : 'commercial_all_archived_jobs_on_the_workflow_manager',
            'desc': "Lists all Archived orders on the Workflow Manager per employee showing order details, sales value and amounts paid / owing for a selected date range. Used to audit Quoterite transactions against your accounting / banking transaction listings. Sales are measured as any quotes converted to orders during the selected date range."
        },

        {
            'name': 'Products Received Not Booked for Install',
            'value': 'commercial_product_received_not_booked_for_install',
            'permission' : 'commercial_product_received_not_booked_for_install',
            'desc': 'All orders on the Workflow Manager that have been archived (not active) showing order details, sales value and amounts paid/owing. . Use this report to audit Smartpad Pro transactions against your accounting/banking transaction listings.'
        },

        {
            'name': 'Products Due',
            'value': 'commercial_products_due',
            'permission' : 'commercial_products_due',
            'desc': 'All orders on the Workflow Manager that have been archived (not active) showing order details, sales value and amounts paid/owing. . Use this report to audit Smartpad Pro transactions against your accounting/banking transaction listings.'
        },

        {
            'name': 'Products Booked for Install',
            'value': 'commercial_products_booked_for_install',
            'permission' : 'commercial_products_booked_for_install',
            'desc': 'All orders on the Workflow Manager that have been archived (not active) showing order details, sales value and amounts paid/owing. . Use this report to audit Smartpad Pro transactions against your accounting/banking transaction listings.'
        },

        {
            'name': 'Total Invoice Raised',
            'value': 'commercial_total_invoice_raised',
            'permission' : 'commercial_total_invoice_raised',
            'desc': 'All orders on the Workflow Manager that have been archived (not active) showing order details, sales value and amounts paid/owing. . Use this report to audit Smartpad Pro transactions against your accounting/banking transaction listings.'
        },

        {
            'name': 'Total Invoice Due Timeframe',
            'value': 'commercial_total_invoice_due_timeframe',
            'permission' : 'commercial_total_invoice_due_timeframe',
            'desc': 'All orders on the Workflow Manager that have been archived (not active) showing order details, sales value and amounts paid/owing. . Use this report to audit Smartpad Pro transactions against your accounting/banking transaction listings.'
        },
        {
            'name': 'Advertising Spend',
            'value': 'advertising_spent',
            'permission' : 'advertising_spent',
            'desc': 'Measures the advertising spend associated with following leads, making appointments, creating quotes and making sales for the selected month(s). It enables analysis of the performance and cost of the different marketing channels in contributing to the sales acheived. Sales are measured as any leads / quotes converted to orders during the selected date range. '
        },
        {
            'name': 'Sales By Product Category - Detail',
            'value': 'sales_by_category_details',
            'permission' : 'sales_by_category_details',
            'desc': 'Measures the total sales by product category and product for a selected date range including average sales and gross profit by value and margin. Any discounts are applied to sales values in proportion to the value of each category in an order. Sales are measured as any quotes converted to orders during the selected date range. '
        },

        {
            'name': 'Supplier Orders by ETA - Consolidated',
            'value': 'supplier_orders_by_eta_consolidated',
            'permission' : 'supplier_orders_by_eta_consolidated',
            'desc': 'The consolidated view of the ETA of products per order grouped according to brand and product category.'
        },

        {
            'name': 'Over And Under - Details',
            'value': 'over_and_under',
            'permission' : 'over_and_under',
            'desc': '--'
        },
        {
            'name': 'Over And Under - Summary',
            'value': 'over_and_under_summary',
            'permission' : 'over_and_under_summary',
            'desc': '--'
        },
        {
            'name': 'Warehouse Work in Progress',
            'value': 'warehouse_work_in_progress',
            'permission' : 'warehouse_work_in_progress',
            'desc': '--'
        },
    ],

    // CHART Filters
    reportCountTypes: [
        { 'name': 'Sales Performance - Type 1', 'value': 'sales_performance_summary_type_1' },
        { 'name': 'Sales Performance - Type 2', 'value': 'sales_performance_summary_type_2' },
        {'name':'Sales By Category', 'value':'sales_by_category_count'},
        {'name':'Sales Stats', 'value':'sales_report_count'},
        {'name':'Sales Performance', 'value':'sales_performance_count'},
        {'name':'Lead Source', 'value':'lead_source_count'},
        {'name':'Lead Status', 'value':'lead_status_count'},
        {'name':'Order Status', 'value':'order_status_count'},
        {'name':'Receipt Report', 'value':'receipt_report_count'},
        // {'name':'Sales List inc Tax', 'value':'sales_list_count'},
        // {'name':'Sales List inc Tax By Person', 'value':'sales_list_person_count'},
        {'name':'Sales Target', 'value' : 'sales_target_count'},
        {'name':'Gross Profit', 'value' : 'gross_profit_count'},
        {'name':'Product Sales', 'value' : 'product_sales_count'},
        {'name':'Appoinments Vs Quotes', 'value' : 'appointment_quotes_count'},
        // {'name':'Product Category Pipeline', 'value' : 'product_category_pipeline_count'},
        // {'name': 'Sales Pipeline', 'value': 'sales_pipeline_count'},
        {'name':'Undelivered Sales', 'value' : 'undelivered_sales_count'},
        {'name':'Completed Jobs', 'value' : 'completed_jobs_count'},
        // {'name': 'Product ETA Report', 'value': 'product_eta_count'},
        // {'name': 'Fabric ETA', 'value': 'fabric_eta_count'},
        // {'name': 'Workroom ETA', 'value': 'workroom_eta_count'},
        {'name':'Leads Summary', 'value' : 'leads_summary'},
        {'name':'Monthly Sales Vs. Actual', 'value' : 'monthly_sales_vs_actual'},
        {'name':'Cash Summary', 'value' : 'cash_summary'},

    ],

    chartTypes: [
        {'name':'Grid', 'value':'grid'},
        // {'name':'Column Chart', 'value':'column'},
        {'name':'Bar Chart', 'value':'bar'},
        {'name':'Pie Chart', 'value':'pie'},
        // {'name':'Map Chart', 'value':'map'},
        {'name':'Line Chart', 'value':'pareto'},
        {'name':'Bar And Line Chart', 'value':'bar_and_line'},
    ],

    dateOptions: ['Today', 'Current Week', 'Last 30 Days','Last 60 Days','This Month','Year to Date'],

    paymentGateway: ['braintree', 'eway', 'securepay','stripe'],

    order_form: {
        codes: [
            // { label:'Logo', value:'logo'},
            { label:'Dealer Name', value:'dealer'},
            { label:'Date Today', value:'date'},
            { label:'Order Date', value:'order_date'},
            { label:'Order Number', value:'order_number'},
            { label:'Sidemark', value:'sidemark'},
            { label:'Quote Label', value:'order_label'},
            { label:'PO #', value:'po_number'},
            { label:'Company Name', value:'company_name'},

            { label:'Internal PO #', value:'purchase_order_id'},
            { label:'Supplier Order #', value:'supplier_order_number'},
            { label:'General Order Notes', value:'general_order_notes'},

            { label:'Sales Representative', value:'employee_full_name'},
            { label:'Customer Full Name', value:'customer'},
            { label:'Customer First Name', value:'customer_first_name'},
            { label:'Customer Last Name', value:'customer_last_name'},
            { label:'Customer Email', value:'customer_email'},
            { label:'Customer Phone', value:'customer_phone'},
            { label:'Customer Mobile', value:'customer_mobile'},
            { label:'Customer Address', value:'customer_address'},
            { label:'Customer State', value:'customer_state'},
            { label:'Customer Suburb', value:'customer_suburb'},
            { label:'Customer Post Code', value:'customer_post_code'},
            { label:'Customer Full Address', value:'customer_full_address'},

            { label:'Delivery Contact', value:'delivery_address_contact'},
            { label:'Delivery Address', value:'delivery_address'},

            { label:'Line Item/s', value:'line_quantity'},
            { label:'Line Cost Price', value:'line_cost_price'},
            { label:'Line Sell Price', value:'line_sell_price'},

            { label:'Quantity', value:'qty'},
            { label:'Line', value:'line'},
            { label:'Width', value:'width'},
            { label:'Drop', value:'height'},
            { label:'Measurement (widthxdrop)', value:'measurement'},
            { label:'Room Name', value:'window'},
            { label:'Room Note', value:'window_note'},
            { label:'Site Mount', value:'site_mount'},
            { label:'Brand Name', value:'brand_name'},
            { label:'Category Name', value:'category_name'},
            { label:'Product Name', value:'product_name'},
            { label:'Product Description', value:'product_description'},
            { label:'Product Note', value:'product_note'},
            { label:'Control Side', value:'control_side'},
            { label:'Fix Type', value:'fix_type'},
            { label:'Control Length', value:'control_length'},
            { label:'Install Types', value:'install_types'},

            { label:'Main Fabric', value:'main_fabric'},
            { label:'Main Fabric Item', value:'main_fabric_item'},
            { label:'Main Fabric Color', value:'main_fabric_color'},
            { label:'Main Fabric Repeat', value:'main_fabric_rep'},
            { label:'Main Fabric Width', value:'main_fabric_width'},

            { label:'Lining', value:'lining'},
            { label:'Lining Item', value:'lining_item'},
            { label:'Lining Color', value:'lining_color'},
            { label:'Lining Repeat', value:'lining_rep'},
            { label:'Lining Width', value:'lining_width'},

            { label:'Total Sheets', value:'TotalSheets'},
            { label:'Sheet Number', value:'SheetNum'},
            { label:'Row Number', value:'RowNum'},
            { label:'Total Row Number', value:'TotalRowNum'},
            { label:'Total Number Of Items', value:'total_number_of_items'},

            { label:'Label Variables', value:'var:{name}'},
            { label:'Bill of Materials [Part Number]', value:'BOM:part_number'},
            { label:'Bill of Materials [Description]', value:'BOM:description'},
            { label:'Bill of Materials [Quantity]', value:'BOM:quantity'},
            { label:'Packing List Lines [Part Number]', value:'PAL:part_number'},
            { label:'Packing List Lines [Description]', value:'PAL:description'},
            { label:'Packing List Lines [Quantity]', value:'PAL:quantity'},
            { label:'Picking List Lines [Part Number]', value:'PIL:part_number'},
            { label:'Picking List Lines [Description]', value:'PIL:description'},
            { label:'Picking List Lines [Quantity]', value:'PIL:quantity'},
            { label:'Picking List Lines [Location]', value:'PIL:location'},
        ],
    },

    accountStatuses: {
        '1': 'Active',
        '2': 'Limited',
        '3': 'Membership Limited',
        '4': 'Membership End',
        '5': 'Pending Approval',
        '6': 'Suspended'
    },

    helpers: [
        { 'name' : 'Dashboard ', 'value' : 'dashboard', 'subs' :
            [
                { 'name' : 'Home', 'value' : 'dashboard.home', 'items' : [
                    { 'name' : 'Tasks And Actions', 'value' : 'dashboard.tasks_and_actions' , 'sub_items' : [
                        { 'name' : 'SMS Information', 'value' : 'dashboard.tasks_and_actions.sms_information' },
                        { 'name' : 'Leads To Book', 'value' : 'dashboard.tasks_and_actions.leads_to_book' },
                        { 'name' : 'Quote To Action', 'value' : 'dashboard.tasks_and_actions.quote_to_action' },
                        { 'name' : 'Appointments Sync Fail', 'value' : 'dashboard.tasks_and_actions.appointments_sync_fail' },
                        { 'name' : 'Invoices / Payments Sync Fail', 'value' : 'dashboard.tasks_and_actions.invoices_or_payments_sync_fail' },
                        { 'name' : 'Online Ordering', 'value' : 'dashboard.tasks_and_actions.online_ordering' },
                        { 'name' : 'Notification List', 'value' : 'dashboard.tasks_and_actions.notification_list' },
                        { 'name' : 'Rules Update', 'value' : 'dashboard.tasks_and_actions.rules' },
                    ]},
                    { 'name' : 'Support', 'value' : 'dashboard.support' , 'sub_items' : [] },
                    { 'name' : 'Software Update', 'value' : 'dashboard.software_update' , 'sub_items' : [] },
                    { 'name' : 'Product Updates', 'value' : 'dashboard.product_updates' , 'sub_items' : [] },
                    { 'name' : 'Item Updates', 'value' : 'dashboard.item_updates' , 'sub_items' : [] },
                    { 'name' : 'Google Calendar', 'value' : 'dashboard.google_calendar' , 'sub_items' : [] },
                    { 'name' : 'Vote Features and Tools', 'value' : 'dashboard.vote_feature_and_tools' , 'sub_items' : [] },
                    { 'name' : 'Features and Functions', 'value' : 'dashboard.features_functions' , 'sub_items' : [] },
                    { 'name' : 'Training Sessions', 'value' : 'dashboard.training_sessions' , 'sub_items' : [] }
                ]},
                { 'name' : 'Performance Dashboard', 'value' : 'dashboard.performance_dashboard', 'items': [] },
                { 'name' : 'Reports', 'value' : 'dashboard.reports', 'items': [] },
            ],
        },
        {
            'name' : 'Quotes ', 'value' : 'quotes', 'subs' :
            [
                { 'name' : 'Quotes', 'value' : 'quotes.quotes' , 'items' : [
                    { 'name' : 'Search Form', 'value' : 'quotes.search_form' }
                ] },
                { 'name' : 'Contacts', 'value' : 'quotes.contacts' , 'items' : [] }
            ]
        },
        {
            'name' : 'Workflow Manager ', 'value' : 'workflow_manager', 'subs' :
            [
                {'name' : 'Workflow Manager', 'value' : 'workflow_manager.workflow_manager' , 'items' : [
                    { 'name' : 'Search Form', 'value' : 'workflow_manager.search_form' }
                ]},
                { 'name' : 'Pending Purchase Orders', 'value' : 'one_click_ordering.pending_purchase_orders' , 'items' : [] },
                { 'name' : 'Past Purchase Orders', 'value' : 'one_click_ordering.past_purchase_orders' , 'items' : [] },
            ],
        },
        {
            'name' : 'Measure & Install', 'value' : 'measure_and_install','subs' :
            [
                { 'name' : 'Measure & Install', 'value' : 'measure_and_install.measure_and_install' , 'items' : [
                    { 'name' : 'Search Form', 'value' : 'measure_and_install.search_form' }
                ] }
            ],
        },
        {
            'name' : 'Scheduling', 'value' : 'scheduling','subs' :
            [
                { 'name' : 'Scheduler', 'value' : 'scheduling.scheduling' , 'items' : [
                    { 'name' : 'Search Form', 'value' : 'scheduling.search_form' }
                ] }
            ],
        },
        {
            'name' : 'Documents', 'value' : 'documents','subs' :
            [
                { 'name' : 'Documents', 'value' : 'documents.documents' , 'items' : [] }
            ],
        },
        {
            'name' : 'Settings', 'value' : 'settings','subs' :
            [
                { 'name' : 'Company Settings', 'value' : 'settings.company_settings', 'items' : [
                    { 'name' : 'Company Surcharges', 'value' : 'settings.quote_surcharges', 'sub_items' : [] },
                    { 'name' : 'Sales Promotions', 'value' : 'settings.sales_promotions', 'sub_items' : [] },
                    { 'name' : 'General Information', 'value' : 'settings.general_information', 'sub_items' : [
                        { 'name' : 'General Information', 'value' : 'settings.general_information.general_information' },
                        { 'name' : 'Danger Zone', 'value' : 'settings.general_information.danger_zone' },
                    ]},
                    { 'name' : 'Form Designs', 'value' : 'settings.form_designs', 'sub_items' : [
                        { 'name' : 'Customer Quotes', 'value' : 'settings.form_design.quotes' },
                        { 'name' : 'Customer Orders/Invoice for Products Purchased', 'value' : 'settings.form_design.orders' },
                        { 'name' : 'Final Measure Sheet and Installation/Fitters Sheet', 'value' : 'settings.form_design.measure_and_install' },
                        { 'name' : 'Customers Receipt and Payment Invoice', 'value' : 'settings.form_design.receipt' },
                        { 'name' : 'Supplier/Manufacturer Order Form', 'value' : 'settings.form_design.order_form' }
                    ]},
                    { 'name' : 'Payments', 'value' : 'settings.payments', 'sub_items' : [
                        // { 'name' : 'Surcharges', 'value' : 'settings.surcharges_and_payment.surcharges' },
                        { 'name' : 'Payment Details', 'value' : 'settings.surcharges_and_payment.payment_details' },
                        { 'name' : 'Payment Gateway', 'value' : 'settings.surcharges_and_payment.payment_gateway' }
                    ]},
                    { 'name' : 'Email Templates', 'value' : 'settings.email_templates', 'sub_items' : [
                        { 'name' : 'Email Templates', 'value' : 'settings.email_templates.email_templates' },
                    ]},
                    { 'name' : 'Delivery Address', 'value' : 'settings.delivery_address', 'sub_items' : [
                        { 'name' : 'Delivery Address', 'value' : 'settings.delivery_addresses.delivery_addresses' },
                    ]},
                    { 'name' : 'Accounting', 'value' : 'settings.accounting', 'sub_items' : [
                        { 'name' : 'QB', 'value' : 'settings.accounting.quickbooks' },
                        { 'name' : 'xero', 'value' : 'settings.accounting.xero' },
                        { 'name' : 'myob', 'value' : 'settings.accounting.myob' },
                    ]},
                ] },
                { 'name' : 'Account Settings', 'value' : 'settings.account_settings', 'items' : [
                    { 'name' : 'General', 'value' : 'settings.account_settings.general', 'sub_items' : [
                        { 'name' : 'Date and Time Format', 'value' : 'settings.account_settings.date_and_time_format' },
                        { 'name' : 'Tax Settings', 'value' : 'settings.account_settings.tax_settings' },
                        { 'name' : 'SMS Activation', 'value' : 'settings.account_settings.sms_activation' },
                        { 'name' : 'Referred By', 'value' : 'settings.account_settings.referred_by' },
                        { 'name' : 'Product Document Categories', 'value' : 'settings.account_settings.product_document_categories' },
                        { 'name' : 'Pricing Tiers', 'value' : 'settings.account_settings.pricing_tiers' },
                        { 'name' : 'Message Template', 'value' : 'settings.account_settings.message_templates' },
                        { 'name' : 'Import & Export Contacts', 'value' : 'settings.account_settings.import_and_export_contacts' },
                        { 'name' : 'Payment Gateway', 'value' : 'settings.account_settings.payment_gateway' },
                        { 'name' : 'Task Defaults', 'value' : 'settings.account_settings.task_defaults' },
                        { 'name' : 'Mandatory Form Fields', 'value' : 'settings.account_settings.mandatory' },
                    ] },
                    { 'name' : 'Scheduler', 'value' : 'settings.account_settings.scheduler', 'sub_items' : [
                        { 'name' : 'Scheduler Settings', 'value' : 'settings.scheduler.scheduler_settings' },
                        { 'name' : 'Appointment Email', 'value' : 'settings.scheduler.appointment_emails' },
                        { 'name' : 'Appointment SMS', 'value' : 'settings.scheduler.appointment_sms' },
                        { 'name' : 'Appointment Statuses', 'value' : 'settings.scheduler.appointment_statuses' },
                        { 'name' : 'Product of Interests', 'value' : 'settings.scheduler.product_interests'}
                    ] },
                    { 'name' : 'Quotes', 'value' : 'settings.account_settings.quotes', 'sub_items' : [
                        { 'name' : 'Faults', 'value' : 'settings.account_settings.faults' },
                        { 'name' : 'Windows Name', 'value' : 'settings.account_settings.windows_name' },
                        { 'name' : 'Install Types', 'value' : 'settings.account_settings.install_types' },
                        { 'name' : 'Quote Statuses', 'value' : 'settings.account_settings.quote_statuses' },
                        { 'name' : 'Fix Types', 'value' : 'settings.account_settings.fix_types' },
                        { 'name' : 'Windows Settings', 'value' : 'settings.account_settings.windows_settings' },
                        { 'name' : 'Quote Tags', 'value' : 'settings.account_settings.quote_tags' },
                        { 'name' : 'Custom Assingment Tags', 'value' : 'settings.account_settings.custom_assignment_tags' },
                        { 'name' : 'Quote Screen Display', 'value' : 'settings.account_settings.quote_screen_display' },
                        { 'name' : 'Quote Payment', 'value' : 'settings.account_settings.quote_payment' },
                    ] },
                    { 'name' : 'Workflow Manager', 'value' : 'settings.account_settings.workflow_manager', 'sub_items' : [
                        { 'name' : 'Wofkflow Order Statuses', 'value' : 'settings.workflow_manager.workflow_order_statuses' },
                        { 'name' : 'Tags', 'value' : 'settings.workflow_manager.tags' },
                        { 'name' : 'Measurement Types', 'value' : 'settings.workflow_manager.measurement_types' },
                        { 'name' : 'Payment Statuses', 'value' : 'settings.workflow_manager.payment_statuses' }
                    ] },
                    { 'name' : 'Measure and Install', 'value' : 'settings.account_settings.measure_and_install', 'sub_items' : [
                        { 'name' : 'Measure Statuses', 'value' : 'settings.account_settings.measure_status' },
                        { 'name' : 'Install Statuses', 'value' : 'settings.account_settings.install_status' }
                    ] },
                    { 'name' : 'Accounting', 'value' : 'settings.account_settings.accounting', 'sub_items' : [
                        { 'name' : 'Quickbooks', 'value' : 'settings.accounting.quickbooks' },
                        { 'name' : 'Xero', 'value' : 'settings.accounting.xero' },
                        { 'name' : 'MyOB', 'value' : 'settings.accounting.myob' }
                    ] },
                ] },
                {'name' : 'Employee & Roles', 'value' : 'settings.employee_and_roles.employee_and_roles' , 'items' : [
                    { 'name' : 'Employees Header', 'value' : 'settings.employee_and_roles.employees', 'sub_items' : [] },
                    { 'name' : 'Roles Header', 'value' : 'settings.employee_and_roles.roles', 'sub_items' : [] },
                    { 'name' : 'Teams Header', 'value' : 'settings.employee_and_roles.teams', 'sub_items' : [] },
                    { 'name' : 'Dashboard', 'value' : 'settings.employee_and_roles.dashboard', 'sub_items' : [
                        { 'name' : 'Home', 'value' : 'settings.employee_and_roles.dashboard.home' },
                        { 'name' : 'Admin Dashboard', 'value' : 'settings.employee_and_roles.dashboard.admin_dashboard' },
                        { 'name' : 'Performance Dashboard', 'value' : 'settings.employee_and_roles.dashboard.performance_dashboard' },
                        { 'name' : 'Tasks And Actions', 'value' : 'settings.employee_and_roles.dashboard.tasks_and_actions' },
                        { 'name' : 'Support', 'value' : 'settings.employee_and_roles.dashboard.support' },
                        { 'name' : 'Software Updates', 'value' : 'settings.employee_and_roles.dashboard.software_updates' },
                        { 'name' : 'Item Updates', 'value' : 'settings.employee_and_roles.dashboard.item_updates' },
                        { 'name' : 'Google Calendar', 'value' : 'settings.employee_and_roles.dashboard.google_calendar' },
                        { 'name' : 'Features And Functions', 'value' : 'settings.employee_and_roles.dashboard.features_and_functions' },
                        { 'name' : 'Product Updates', 'value' : 'settings.employee_and_roles.dashboard.product_updates' },
                        { 'name' : 'Vote Features and Tools', 'value' : 'settings.employee_and_roles.dashboard.vote_features_and_tools' },
                        { 'name' : 'Training Sessions', 'value' : 'settings.employee_and_roles.dashboard.training_sessions' },
                    ]},
                    { 'name' : 'Scheduling', 'value' : 'settings.employee_and_roles.scheduling', 'sub_items' : [
                        { 'name' : 'Scheduler', 'value' : 'settings.employee_and_roles.scheduling.scheduler' }
                    ]},
                    { 'name' : 'Quotes', 'value' : 'settings.employee_and_roles.quotes', 'sub_items' : [
                        { 'name' : 'Quotes', 'value' : 'settings.employee_and_roles.quotes.quotes' },
                        { 'name' : 'Quote Documents', 'value' : 'settings.employee_and_roles.quotes.quote_documents' }
                    ]},
                    { 'name' : 'Commercial Buildings', 'value' : 'settings.employee_and_roles.commercial_buildings', 'sub_items' : [
                        { 'name' : 'Buildings', 'value' : 'settings.commericial_buildings.buildings.buildings' },
                        { 'name' : 'Notes', 'value' : 'settings.commericial_buildings.notes.notes' },
                        { 'name' : 'Documents', 'value' : 'settings.commericial_buildings.documents.documents' },
                    ]},
                    { 'name' : 'Commercial Opportunities', 'value' : 'settings.employee_and_roles.commercial_opportunities', 'sub_items' : [
                        { 'name' : 'Quotes', 'value' : 'settings.commercial_opportunities.quotes.quotes' },
                        { 'name' : 'Workflow Manager', 'value' : 'settings.commercial_opportunities.workflow_manager.workflow_manager' },
                        { 'name' : 'Opportunities', 'value' : 'settings.commercial_opportunities.opportunities.opportunities' },
                        { 'name' : 'Notes', 'value' : 'settings.commercial_opportunities.notes.notes' },
                        { 'name' : 'Documents', 'value' : 'settings.commercial_opportunities.documents.documents' },
                    ]},
                    { 'name' : 'Commercial Businesses', 'value' : 'settings.employee_and_roles.commericial_businesses', 'sub_items' : [
                        { 'name' : 'Businesses', 'value' : 'settings.commericial_businesses.businesses.businesses' },
                        { 'name' : 'Notes', 'value' : 'settings.commericial_businesses.notes.notes' },
                        { 'name' : 'Documents', 'value' : 'settings.commericial_businesses.documents.documents' },
                    ]},
                    { 'name' : 'Commercial Contacts', 'value' : 'settings.commericial_contacts', 'sub_items' : [
                        { 'name' : 'Contacts', 'value' : 'settings.commericial_contacts.contacts.contacts' },
                        { 'name' : 'Notes', 'value' : 'settings.commericial_contacts.notes.notes' },
                        { 'name' : 'Documents', 'value' : 'settings.commericial_contacts.documents.documents' },
                    ]},
                    { 'name' : 'Workflow Manager', 'value' : 'settings.employee_and_roles.workflow_manager', 'sub_items' : [
                        { 'name' : 'Workflow Manager', 'value' : 'settings.employee_and_roles.workflow_manager.workflow_manager' }
                    ]},
                    { 'name' : 'Payments', 'value' : 'settings.employee_and_roles.payments', 'sub_items' : [
                        { 'name' : 'Payments', 'value' : 'settings.employee_and_roles.payments.payments' },
                    ]},
                    { 'name' : 'Measure And Install', 'value' : 'settings.employee_and_roles.measure_and_install', 'sub_items' : [
                        { 'name' : 'Measure And Install', 'value' : 'settings.employee_and_roles.measure_and_install.measure_and_install' },
                        { 'name' : 'Measure', 'value' : 'settings.employee_and_roles.measure_and_install.measure' },
                        { 'name' : 'Install', 'value' : 'settings.employee_and_roles.measure_and_install.install' },
                        { 'name' : 'Other Jobs', 'value' : 'settings.employee_and_roles.measure_and_install.other_jobs' }
                    ]},
                    { 'name' : 'Contacts', 'value' : 'settings.employee_and_roles.contacts', 'sub_items' : [
                        { 'name' : 'Contacts', 'value' : 'settings.employee_and_roles.contacts.contacts' },
                        { 'name' : 'Client Documents', 'value' : 'settings.employee_and_roles.contacts.client_documents' }
                    ]},
                    { 'name' : 'Products', 'value' : 'settings.employee_and_roles.products', 'sub_items' : [
                        { 'name' : 'Product Settings', 'value' : 'settings.employee_and_roles.products.product_settings' },
                        { 'name' : 'Product Builder', 'value' : 'settings.employee_and_roles.products.product_builder' },
                        { 'name' : 'Product Measurements', 'value' : 'settings.employee_and_roles.products.product_measurements' }
                    ]},
                    { 'name' : 'Items', 'value' : 'settings.employee_and_roles.items', 'sub_items' : [
                        { 'name' : 'Item Settings', 'value' : 'settings.employee_and_roles.items.item_settings' },
                        { 'name' : 'Item Check', 'value' : 'settings.employee_and_roles.items.item_check' }
                    ]},
                    { 'name' : 'Reports', 'value' : 'settings.employee_and_roles.reports', 'sub_items' : [
                        { 'name' : 'Reports', 'value' : 'settings.employee_and_roles.reports.reports' },
                    ]},
                    { 'name' : 'Documents', 'value' : 'settings.employee_and_roles.documents', 'sub_items' : [
                        { 'name' : 'Documents', 'value' : 'settings.employee_and_roles.documents.documents' },
                    ]},
                    { 'name' : 'Employee & Roles', 'value' : 'settings.employee_and_roles.employee_and_roles', 'sub_items' : [
                        { 'name' : 'Employee & Roles Main Access', 'value' : 'settings.employee_and_roles.employee_and_roles.employee_and_roles_main_access' },
                        { 'name' : 'Employees', 'value' : 'settings.employee_and_roles.employee_and_roles.employees' },
                        { 'name' : 'Roles', 'value' : 'settings.employee_and_roles.employee_and_roles.roles' },
                        { 'name' : 'Teams', 'value' : 'settings.employee_and_roles.employee_and_roles.teams' },
                    ]},
                    { 'name' : 'Company Settings', 'value' : 'settings.employee_and_roles.company_settings', 'sub_items' : [
                        { 'name' : 'Company Settings', 'value' : 'settings.employee_and_roles.company_settings.company_settings' },
                        { 'name' : 'Delivery Addresses', 'value' : 'settings.employee_and_roles.company_settings.delivery_addresses' },
                    ]},
                    { 'name' : 'Account Settings', 'value' : 'settings.employee_and_roles.account_settings', 'sub_items' : [
                        { 'name' : 'Account Settings Page Access', 'value' : 'settings.employee_and_roles.account_settings.account_settings_page_access' },
                        { 'name' : 'Payment Gateways', 'value' : 'settings.employee_and_roles.account_settings.payment_gateways' },
                        { 'name' : 'SMS Settings', 'value' : 'settings.employee_and_roles.account_settings.sms_settings' },
                        { 'name' : 'Tax Settings', 'value' : 'settings.employee_and_roles.account_settings.tax_settings' },
                        { 'name' : 'Measure & Install', 'value' : 'settings.employee_and_roles.account_settings.measure_and_install' },
                        { 'name' : 'Appointment Reminders', 'value' : 'settings.employee_and_roles.account_settings.appointment_reminders' },
                        { 'name' : 'Appointment Statuses', 'value' : 'settings.employee_and_roles.account_settings.appointment_statuses' },
                        { 'name' : 'Referred By', 'value' : 'settings.employee_and_roles.account_settings.referred_by' },
                        { 'name' : 'Product Document Categories', 'value' : 'settings.employee_and_roles.account_settings.product_document_categories' },
                        { 'name' : 'Windows', 'value' : 'settings.employee_and_roles.account_settings.windows' },
                        { 'name' : 'Install Type', 'value' : 'settings.employee_and_roles.account_settings.install_type' },
                        { 'name' : 'Order Statuses', 'value' : 'settings.employee_and_roles.account_settings.order_statuses' },
                        { 'name' : 'Tags', 'value' : 'settings.employee_and_roles.account_settings.tags' },
                        { 'name' : 'Quote Statuses', 'value' : 'settings.employee_and_roles.account_settings.quote_statuses' },
                        { 'name' : 'Fix Type', 'value' : 'settings.employee_and_roles.account_settings.fix_type' },
                        { 'name' : 'Product Order Measurement Type', 'value' : 'settings.employee_and_roles.account_settings.product_order_measurement_type' },
                        { 'name' : 'Import & Export Contacts', 'value' : 'settings.employee_and_roles.account_settings.import_and_export_contacts' },
                        { 'name' : 'Tiers', 'value' : 'settings.employee_and_roles.account_settings.tiers' },
                        { 'name' : 'Message Templates', 'value' : 'settings.employee_and_roles.account_settings.message_templates' },
                        { 'name' : 'Document Templates', 'value' : 'settings.employee_and_roles.account_settings.document_templates' },
                        { 'name' : 'Payment Statuses', 'value' : 'settings.employee_and_roles.account_settings.payment_statuses' },
                        { 'name' : 'Quote Tags', 'value' : 'settings.employee_and_roles.account_settings.quote_tags' },
                        { 'name' : 'Custom Assign Tags', 'value' : 'settings.employee_and_roles.account_settings.custom_assign_tags' },
                        { 'name' : 'Product Interests', 'value' : 'settings.employee_and_roles.account_settings.product_interests' },
                        { 'name' : 'Faults', 'value' : 'settings.employee_and_roles.account_settings.faults' },
                        { 'name' : 'Mandatory Fields', 'value' : 'settings.employee_and_roles.account_settings.mandatory_fields' },
                        { 'name' : 'Commercial Classifications', 'value' : 'settings.employee_and_roles.account_settings.commercial_classifications' },
                    ]},
                    { 'name' : 'Accounting Systems', 'value' : 'settings.employee_and_roles.accounting_systems', 'sub_items' : [
                        { 'name' : 'Account Connections', 'value' : 'settings.employee_and_roles.accounting_systems.account_connections' },
                        { 'name' : 'Unsync Invoices & Payments', 'value' : 'settings.employee_and_roles.accounting_systems.unsync_invoices_and_payments' },
                        { 'name' : 'Accounting Tags', 'value' : 'settings.employee_and_roles.accounting_systems.accounting_tags' },
                    ]},
                    { 'name' : 'Subscription', 'value' : 'settings.employee_and_roles.subscription', 'sub_items' : [
                        { 'name' : 'Subscription', 'value' : 'settings.employee_and_roles.subscription.subscription' },
                    ]},
                ]},
                { 'name' : 'Product Settings', 'value' : 'settings.product_settings' , 'items' : [
                    { 'name' : 'Product Settings', 'value' : 'settings.product_settings.product_settings' }
                ] },
                { 'name' : 'Item Settings', 'value' : 'settings.item_settings' , 'items' : [
                    { 'name' : 'Item Settings', 'value' : 'settings.item_settings.item_settings' }
                ] },
                { 'name' : 'Product Measurements', 'value' : 'settings.product_measurements' , 'items' : [
                    { 'name' : 'Product Measurements', 'value' : 'settings.product_measurements.product_measurements' }
                ] },
                { 'name' : 'Item Check', 'value' : 'settings.item_check' , 'items' : [
                    { 'name' : 'Item Check', 'value' : 'settings.item_check.item_check' }
                ] },
            ],
        },
    ],

    fileTypes: {
        jpeg:  'image',
        jpg:  'image',
        png:  'image',
        gif:  'image',
        svg: 'image',
        pdf:  'pdf',
        doc:  'word',
        docx: 'word',
        odt: 'word',
        csv:  'excel',
        txt:  'text',
        xls:  'excel',
        xlsx: 'excel',
        ods: 'excel',
        msg: 'text',
    },

    friendlyFileTypes: {
        image: 'image',
        pdf: 'pdf',
        word: 'word',
        excel: 'excel',
        text: 'text'
    },

    payment_methods: [
        { value: '',  label: 'Default' },
        { value: 'cod',  label: 'COD' },
        { value: 'account',  label: 'Account' },
    ],

    shipping_method_types: [
        { value: 'FIXED',  label: 'Fixed' },
        { value: 'PERCENT',  label: 'Percent' },
        { value: 'SCRIPT',  label: 'Script' }
    ],

    error_messages: {
        'WorkflowStepsRequired' : 'Workflow Step is Required!'
    },

    discounts: ['% Discount', '% Split Discount'],
    discounts_multiplier: ['Multiplier', 'Split Multiplier'],

    calculation_types: {
        cost_multiply: 'Cost Multiply',
        discount_received: 'Discount Received'
    },

    discount_types: {
        PERCENT_DISCOUNT: '% Discount',
        SPLIT_DISCOUNT: ' % Split Discount'
    },
};
export default CONSTANTS;
